import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueGtag from 'vue-gtag';

const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueGtag, {
  config: { 
    id: "UA-90940177-1",
  },
}, 
router
);
app.mount('#app');
