<template>
    <header :class="scroll">
        <div class="logo">
            <router-link :to="{ name : 'Home' }">
                <img src="./../assets/logo-marjonat.png"  alt="logo"/>
            </router-link>
        </div>
        <div class="menu">
            <nav>
                <router-link :to="{ name : 'Home' }" class="menu-link">Accueil</router-link>
                <router-link :to="{ name : 'Advert' }" class="menu-link">Régie publicitaire</router-link>
                <router-link :to="{ name : 'Agency' }" class="menu-link">Agence créative</router-link>
                <router-link :to="{ name : 'Achievement' }" class="menu-link">Références</router-link>
                <router-link :to="{ name : 'About' }" class="menu-link">Qui sommes-nous ?</router-link>
                <router-link :to="{ name : 'Contact' }" class="menu-link">Nous contacter</router-link>
            </nav>
        </div>
        <!-- MENU RESP -->
        <div class="menu-header-resp">
            <div class="burger-nav" @click="$store.state.menu_open = !$store.state.menu_open" :class="$store.state.menu_open ? 'open' : ''" >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <nav class="menu-header-resp-content" v-if="$store.state.menu_open">
                <router-link :to="{ name : 'Home' }" class="menu-link">Accueil</router-link>
                <router-link :to="{ name : 'Advert' }" class="menu-link">Régie publicitaire</router-link>
                <router-link :to="{ name : 'Agency' }" class="menu-link">Agence créative</router-link>
                <router-link :to="{ name : 'Achievement' }" class="menu-link">Références</router-link>
                <router-link :to="{ name : 'About' }" class="menu-link">Qui sommes-nous ?</router-link>
                <router-link :to="{ name : 'Contact' }" class="menu-link">Nous contacter</router-link>            
            </nav>
        </div>
    </header>
</template>

<script>
export default {
    name : "HeaderApp",
    data() {
        return {
            scroll: null
        }
    },
    created () {
        window.addEventListener('scroll', this.handleScroll);
        this.getScroll();
    },
    beforeUnmount () {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
         handleScroll() {
             this.getScroll();
        },
        getScroll() {
            if(window.pageYOffset < 100) {
                this.scroll = null;
            }else {
                this.scroll = "scroll"
            }
        }
    },
    watch  : {
        '$route.name' : function() {
            this.getScroll();
        } 
    }
}
</script>

<style lang="scss" scoped>
@import "./../scss/variables.scss";
    header {
        position:fixed;
        top: 40px;
        left:0;
        right:0;
        background: #f3f3f3;
        padding:20px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        transition:0.5s;
        z-index:99;
    }

    header.scroll {
        top:0;
        padding:10px 0;
    }

    .logo {
        margin-right:40px;
    }

    .menu-link {
        color:$secondColor;
        text-transform: uppercase;
        padding:5px 0;
        margin:0 10px;
        font-weight:400;
        font-size:15px;
        text-decoration: none;
        transition:0.5s;
    }

    .menu-link:hover {
        color:$mainColor;
    }

     /* MENU RESP */
    .menu-header-resp {
        display: none;
    }

    .burger-nav {
        width: 40px;
        height: 40px;
        position: relative;
        margin: 20px auto;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.5s ease-in-out;
        -moz-transition: 0.5s ease-in-out;
        -o-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;
        cursor: pointer;
    }

    .burger-nav span {
        display: block;
        position: absolute;
        height: 7px;
        width: 100%;
        background: $mainColor;
        border-radius: 7px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
    }

    .burger-nav span:nth-child(1) {
        top: 0px;
    }

    .burger-nav span:nth-child(2),
    .burger-nav span:nth-child(3) {
        top: 14px;
    }

    .burger-nav span:nth-child(4) {
        top: 28px;
    }

    .burger-nav.open span:nth-child(1) {
        top: 18px;
        width: 0%;
        left: 50%;
    }

    .burger-nav.open span:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .burger-nav.open span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    .burger-nav.open span:nth-child(4) {
        top: 18px;
        width: 0%;
        left: 50%;
    }

    .menu-header-resp-content {
        position: fixed;
        top: 140px;
        width: 300px;
        right: 0;
        background: #fff;
        border-bottom: 1px solid $secondColor;
        display: flex;
        flex-direction: column;
        transition: 0.5s;
    }

    .scroll .menu-header-resp-content {
        top: 80px;
    }

    .menu-header-resp-content .menu-link {
        width: calc(100% - 40px);
        border: none;
        height: 40px;
        justify-content: flex-start;
        padding: 0 20px;
        align-items: center;
        text-decoration: none;
        display: flex;
    }

    .menu-header-resp-content .menu-link .menu-text {
        display: flex;
        position: relative;
        width: calc(100% - 22px);
        height: 40px;
        align-items: center;
        bottom: 0;
        text-decoration: none;
        text-align: left;
        justify-content: flex-start;
        margin-left: 20px;
    }

    .menu-header-resp-content .router-link-exact-active.menu-item {
        background: $mainColor;
    }

    .menu-header-resp-content .router-link-exact-active .menu-highlight {
        display: none;
    }

    .menu-header-resp-content .router-link-exact-active.menu-item .icon-menu:before,
    .menu-header-resp-content .router-link-exact-active.menu-item .menu-text {
        color: #fff;
    }

    .link-ext-resp.menu-link {
        color:#fff;
        margin:0;
        display: flex;
        align-items: center;
        padding:0 40px;
    }

    @media screen and (max-width:1200px) {
        .menu-header-resp {
            display: flex;
            margin-right:20px;
        }

        .logo {
            margin-left: 20px;
        }

        .menu {
            display: none;
        }
        
        header {
            justify-content: space-between;
            padding:10px 0;
        }

        header.scroll {
            padding:0;
        }


    }
</style>