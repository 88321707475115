<template>
    <div class="cookies-bar" v-if="$store.state.cookies_display">
        <div class="cookies-container">
            <p>Nous utilisons des cookies pour vous garantir la meilleure expérience sur notre site web. Si vous continuez à utiliser ce site, nous supposerons que vous en êtes satisfait.</p>
            <div class="btn" @click="$store.commit('closeCookies')">OK</div>
        </div>
        <div class="btn-close" @click="$store.commit('closeCookies')">X</div>
    </div>
    <div class="cookies-light" v-else @click="$store.commit('openCookies')">
        Cookies
    </div>
</template>

<script>

export default {
    name : "Cookies",
    data() {
        return {
            accept : true
        }
    },
    created() {
        const verify = this.getCookie('MarjonatGA');
        if(verify != "") {
            this.$store.commit('closeCookies');
        }
        let expiryDate = new Date();
        document.cookie = `MarjonatGA=${this.accept}; expires=${expiryDate}; path=/`;
    },
    methods : {
        getCookie(cname) {
            let name = cname + "=";
            let decodedCookie = decodeURIComponent(document.cookie);
            let ca = decodedCookie.split(';');
            for(let i = 0; i <ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
                }
            }
            return "";
        },
    },

}
</script>


<style lang="scss" scoped>
@import "./../scss/variables.scss";

.cookies-bar {
    position:fixed;
    right:0;
    left:0;
    bottom:0;
    padding:10px 0;
    background:$mainColor;
    display: flex;
    justify-content: center;
    z-index: 9999;
}

.cookies-container {
    display: flex;
    justify-content: space-between;
    align-items:center;
    width:800px;
    max-width: 90%;
}

p {
    color:#fff;
    margin-right:20px;
}

.btn-close {
    position:absolute;
    top:-15px;
    right:20px;
    color:#fff;
    border-radius:50%;
    background:$secondColor;
    height:30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items:center;
    cursor: pointer;
}

.cookies-light {
    position: fixed;
    bottom:0;
    right:20%;
    padding:5px 10px;
    background:$mainColor;
    cursor: pointer;
    color:#fff;
    z-index: 999;
}

.btn {
    margin:0;
    font-size:15px;
    padding:5px 25px;
}

.btn:hover {
    background:$thirdColor;
}


</style>
