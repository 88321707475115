<template>
    <footer>
        <div class="footer-main">
            <div class="col-33">
                <h3>Nos réalisations</h3>
                <div class="projects-container">
                    <div
                        v-for="(item, idx) in  projects"
                        :key="idx" 
                        class="project-item" 
                        @click="openProject(item.id)"
                    >
                        <img :src="item.footer" class="project-img" alt="project" />
                    </div> 
                </div>
            </div>
            <div class="col-33 col-menu">
                <router-link :to="{ name : 'Home' }" class="menu-link">Accueil</router-link>
                <router-link :to="{ name : 'Advert' }" class="menu-link">Régie publicitaire</router-link>
                <router-link :to="{ name : 'Agency' }" class="menu-link">Agence créative</router-link>
                <router-link :to="{ name : 'Achievement' }" class="menu-link">Références</router-link>
                <router-link :to="{ name : 'About' }" class="menu-link">Qui sommes-nous ?</router-link>
                <router-link :to="{ name : 'Contact' }" class="menu-link">Nous contacter</router-link>
            </div>
            <div class="col-33">
                <div class="address-container">
                    <div class="icon-map"></div>
                    <p>
                        4, Rue Mahatma Gandhi<br />
                        Espace Beauvalle – Bât. B<br />
                        13 090 Aix-en-Provence<br />
                        France
                    </p>
                </div>
            </div>
        </div>
        <div class="footer-copyright">
            <router-link :to="{ name : 'LegalNotices'}" class="legal-link">Mentions légales</router-link> – Copyright © {{currentYear}} MARJONAT DISTRIBUTION – Tous droits réservés
        </div>
    </footer>
</template>

<script>
export default {
    name : "FooterApp",
    data() {
        return {
            currentYear : new Date().getFullYear(),
            projects : []
        }
    },
    mounted(){
        this.randomProjects();
    },
    methods: {
        openProject(id) {
            this.$store.commit("setProject", id);
            this.$router.push({ name : "Achievement"});
        },
        randomProjects() {
            this.projects = this.$store.state.PROJECTS.sort(() => 0.5 - Math.random());
            this.projects = this.projects.slice(0,12);
        }
    },
    watch : {
        '$route' : function() {
            this.randomProjects();
        }
    }
}
</script>

<style lang="scss" scoped>
@import "./../scss/variables.scss";
    footer {
        background:$mainColor;
    }

    .footer-main {
        display: flex;
        justify-content: center;
        align-items: center;
        padding:40px 0;
        max-width: 90%;
        width:1000px;
        margin:0 auto;
    }

    .footer-main .col-33 {
        width:31%;
        margin:0 1%;
        display: flex;
        flex-direction: column;
    }

    h3 {
        color:#fff;
        font-weight:400;
        text-align: left;
        font-size:22px;
    }
    
    .menu-link {
        text-decoration: none;
        color:#fff;
        line-height:25px;
    }

    .menu-link:hover {
        text-decoration: underline;
    }

    p {
        color:#fff;
        line-height:25px;
        text-align:left;
    }

    .address-container {
        display: flex;
        align-items: center;
    }

    .icon-map {
        height:40px;
        width:40px;
        background-image: url("./../assets/icon-map-footer.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-right:20px;
    }


    .footer-copyright {
        background:$secondColor;
        text-align: center;
        padding:15px 10px;
        color:#fff;
        font-weight: 400;
    }

    .legal-link {
        color:#fff;
        text-decoration: none;
    }
    
    .legal-link:hover {
        text-decoration: underline;
    }

    .projects-container {
        display: flex;
        flex-wrap: wrap;
        padding:20px 0;
        width:260px;
    }

    .project-item {
        cursor: pointer;
        margin:2px 4px;

    }

    .project-img {
        max-width: 100%;
        display: block;
        margin:0 auto;
        transition: 0.5;
    }

    .project-img:hover {
        filter: grayscale(100%);
    }

    @media screen and (max-width:870px) {
        .footer-main .col-33.col-menu {
            display: none;
        }

        .footer-main .col-33 {
            width:98%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .footer-main  {
            flex-direction: column;
        }

        .address-container {
            flex-direction: column;
        }

        .icon-map {
            margin-right: 0;
            margin-bottom: 10px;
        }

        p {
            text-align: center;
        }
    }
</style>