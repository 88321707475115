import { createRouter, createWebHistory } from 'vue-router'
import store from './../store/index';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import( '../views/Home.vue')
  },
  {
    path: '/nous-connaitre',
    name: 'About',
    component: () => import( '../views/About.vue')
  },
  {
    path: '/regie-publicitaire',
    name: 'Advert',
    component: () => import( '../views/Advert.vue')
  },
  {
    path: '/agence-creative',
    name: 'Agency',
    component: () => import( '../views/Agency.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import( '../views/Contact.vue')
  },
  {
    path: '/nos-realisations',
    name: 'Achievement',
    component: () => import( '../views/Achievement.vue')
  },
  {
    path: '/mentions-legales',
    name: 'LegalNotices',
    component: () => import( '../views/LegalNotices.vue')
  },

]



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }else {
      return { top :0 }
    }
  },
})

router.beforeEach((to , from, next) => {
  store.state.menu_open = false
  next();
})



export default router
