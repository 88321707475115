<template>
  <header-app></header-app>
  <router-view/>
  <footer-app></footer-app>
  <cookies></cookies>
</template>

<script>
import FooterApp from "./components/FooterApp";
import HeaderApp from "./components/HeaderApp";
import Cookies  from "./components/Cookies.vue";
 
export default {
  name : "App",
  components : {
    FooterApp,
    HeaderApp,
    Cookies
  }
}
</script>

<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
@import "./scss/variables.scss";
  body, h1, h2, h3, p {
    padding:0;
    margin:0;
    font-family: 'Source Sans Pro', sans-serif;
  }

  .box {
    width:95%;
    margin:0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .section-default {
    min-height: 100vh;
    display: flex;
    width:100%;
    position:relative
  }

  .col-left {
    width:25%;    
    display: flex;
    align-items: center;
  }

  .col-left-container {
    margin: 0 20%;
  }

  .col-left-container h2 {
    color:#fff;
    margin-bottom:20px;
    text-transform: uppercase;
    font-size:25px;
    font-weight: 400;
  }

  .col-left-container p {
    color:#fff;
    font-size:18px;
    font-weight: 400;
  }

  .col-main {
    width:75%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position:relative;
  }

  .col-main-first {
    padding-top:150px;
  }
  .bg-main {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width:100%;
    height:100%;
    transition:1.5s;
  }

  .section-default:hover .bg-main {
    transform : scale(1.2);
  } 

  .col-container {
    display: flex;
    width:100%;
    justify-content: center;
    position:absolute;
    top:calc(50% - 150px);
    left:0;
    right:0;
  }

  .col-container .col-25 {
    width:21%;
    margin:0 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  
  .col-container .col-33 {
    width:31%;
    margin:0 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .col-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height:200px;
  }

  .col-item p {
    color:#fff;
    text-align: center;
    font-size:25px;
    text-transform: uppercase;
    line-height: 1.5em;
  }
  

  .btn {
    font-size:20px;
    color:$mainColor;
    border:1px solid $mainColor;
    padding:10px 35px;
    text-transform: uppercase;
    border-radius:5px;
    transition:0.5s;
    background:#fff;
    margin-top:40px;
    text-decoration: none;
    cursor: pointer;
  }

  .col-third .btn {
    border:1px solid $thirdColor;
    color:$thirdColor;
    background:none;
  }

  .btn:hover {
    background:$mainColor;
    color:#fff;
  }

  .col-third .btn:hover {
    background:$thirdColor;
    color:$secondColor;
  }

  @media screen and (max-width:1100px) {
    
    .section-default {
      flex-direction: column;
      min-height: auto;
    }

    .col-left{
      width:calc(100% - 40px);
      padding:40px 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .col-left h2, .col-left p {
      text-align: center;
    }

    .col-main {
      min-height:80vh;
      width:100%;
    }

    .bg-main {
      min-height: 80vh;
    }

    .col-container {
      flex-wrap: wrap;
      position: relative;
    }

    .col-container .col-25 {
      width:46%;
      margin:40px 2%;
    }

    .col-left-container {
      margin:0 1%;
    }

    .bg-main {
      display: none;
    }
  }

  @media screen and (max-width:768px) {
    .col-container .col-25, .col-container .col-33 {
      width:96%;
    }

    .col-item {
      height:auto;
    }

    .btn {
      margin-top: 20px;
    }



    .col-main  {
      height:auto;
      min-height: auto;
      padding:80px 0;
    }

    .section-default {
      min-height: auto;
    }
  }

 

</style>
